<script setup>
import CFTicketModalInner from '@/components/Ticket/CFTicketModalInner.vue';
import CFButton from '@/components/CFButton.vue';
import { useTicketStore } from '@/stores/ticket.js';
import CreateTicketStep from '@/components/Ticket/Steps/CreateTicketStep.vue';
import TicketStartStep from '@/components/Ticket/Steps/TicketStartStep.vue';
import ProductSelectStep from '@/components/Ticket/Steps/ProductSelectStep.vue';
import { useRouter } from 'vue-router';
import { useBusinessHours } from '@/composables/useBusinessHours.js';

const ticketStore = useTicketStore();
const router = useRouter();

const { isInsideBusinessHours, isBeforeBusinessHours } = useBusinessHours();

const goToSelectProductStep = () => {
    ticketStore.setComponent(ProductSelectStep, TicketStartStep);
};

const goToCreateTicketStep = () => {
    ticketStore.setComponent(CreateTicketStep, TicketStartStep);
};

const goToMigrationHelper = () => {
    ticketStore.close();
    router.push({ name: 'migration' });
};

const onCancel = () => {
    ticketStore.goToPrevious();
};
</script>

<template>
    <CFTicketModalInner :title="$t('support.create_ticket_title')">
        <div
            v-if="!isInsideBusinessHours()"
            class="border-primary mb-6 border"
        >
            <div class="bg-primary px-4 py-2">
                <h3 class="text-xs font-semibold text-white uppercase">
                    {{ $t('support.status') }}
                </h3>
            </div>
            <div class="text-primary p-6 text-base leading-tight font-semibold">
                {{ isBeforeBusinessHours() ? $t('support.status_offline_before') : $t('support.status_offline_after') }}
            </div>
        </div>

        <div class="flex flex-col gap-2">
            <div class="flex w-full items-center gap-2 bg-white px-6 py-4">
                <div class="grow">
                    <span class="block font-bold">{{ $t('support.question_about_product') }}</span>
                </div>
                <CFButton
                    type="button"
                    class="shrink-0"
                    outline
                    @click="goToSelectProductStep"
                >
                    {{ $t('button.select_product') }}
                </CFButton>
            </div>

            <div class="flex w-full items-center gap-2 bg-white px-6 py-4">
                <div class="grow">
                    <span class="block font-bold">{{ $t('support.general_question') }}</span>
                </div>
                <CFButton
                    type="button"
                    class="shrink-0"
                    outline
                    @click="goToCreateTicketStep"
                >
                    {{ $t('button.ask_question') }}
                </CFButton>
            </div>

            <div class="flex w-full items-center gap-2 bg-white px-6 py-4">
                <div class="grow">
                    <span class="block font-bold">{{ $t('support.migration') }}</span>
                    <span class="text-neutrals-700 text-xs">
                        {{ $t('support.migration_help') }}
                    </span>
                </div>
                <CFButton
                    type="button"
                    class="shrink-0"
                    outline
                    @click="goToMigrationHelper"
                >
                    {{ $t('support.migration_help_link') }}
                </CFButton>
            </div>
        </div>

        <template #actions>
            <CFButton
                type="button"
                outline
                @click="onCancel"
            >
                {{ $t('button.cancel') }}
            </CFButton>
        </template>
    </CFTicketModalInner>
</template>
