<template>
    <div class="bg-accent flex items-center justify-center gap-x-6 px-6 py-2.5 text-white sm:px-3.5">
        <svg
            class="size-4 shrink-0"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="-1.2 -1.2 24 24"
            height="24"
            width="24"
        >
            <path
                d="m21.393 18.513 -9 -17.1A1.8 1.8 0 0 0 10.8 0.45a1.8 1.8 0 0 0 -1.593 0.9630000000000001l-9 17.1a1.8 1.8 0 0 0 0.054 1.8A1.8 1.8 0 0 0 1.8 21.150000000000002h18a1.8 1.8 0 0 0 1.593 -2.637ZM9.9 7.65a0.9 0.9 0 0 1 1.8 0v5.4a0.9 0.9 0 0 1 -1.8 0ZM10.845 18a1.377 1.377 0 0 1 -1.368 -1.323A1.332 1.332 0 0 1 10.8 15.3a1.377 1.377 0 0 1 1.368 1.323A1.332 1.332 0 0 1 10.845 18Z"
                fill="currentColor"
                stroke-width="1"
            ></path>
        </svg>
        <div>
            <slot />
        </div>
    </div>
</template>
