<script setup>
import bodymovin from 'lottie-web/build/player/lottie_light';
import { onMounted, useTemplateRef } from 'vue';

const props = defineProps({
    size: {
        type: String,
        default: 'large',
    },
    color: {
        type: String,
        default: 'black',
    },
});

const blackSpinner = {
    nm: 'Comp 1',
    ddd: 0,
    h: 500,
    w: 500,
    meta: { g: '@lottiefiles/toolkit-js 0.33.2' },
    layers: [
        {
            ty: 4,
            nm: 'Untitled-2 Outlines 2',
            sr: 1.5,
            st: 0,
            op: 4500,
            ip: 0,
            hd: false,
            ddd: 0,
            bm: 0,
            hasMask: false,
            ao: 0,
            ks: {
                a: { a: 0, k: [249.874, 238.486, 0], ix: 1 },
                s: { a: 0, k: [100, 100, 100], ix: 6 },
                sk: { a: 0, k: 0 },
                p: { a: 0, k: [266, 229, 0], ix: 2 },
                r: {
                    a: 1,
                    k: [
                        { o: { x: 0.333, y: 0 }, i: { x: 0, y: 1 }, s: [0], t: 0 },
                        { o: { x: 0.333, y: 0 }, i: { x: 0, y: 1 }, s: [360], t: 90 },
                        { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [720], t: 180 },
                        { s: [0], t: 281 },
                    ],
                    ix: 10,
                },
                sa: { a: 0, k: 0 },
                o: { a: 0, k: 100, ix: 11 },
            },
            ef: [],
            shapes: [
                {
                    ty: 'gr',
                    bm: 0,
                    hd: false,
                    mn: 'ADBE Vector Group',
                    nm: 'Group 1',
                    ix: 1,
                    cix: 2,
                    np: 3,
                    it: [
                        {
                            ty: 'sh',
                            bm: 0,
                            hd: false,
                            mn: 'ADBE Vector Shape - Group',
                            nm: 'Path 1',
                            ix: 1,
                            d: 1,
                            ks: {
                                a: 0,
                                k: {
                                    c: false,
                                    i: [
                                        [0, 0],
                                        [47.05, 0],
                                        [24.79, 36.05],
                                    ],
                                    o: [
                                        [-24.79, 36.05],
                                        [-47.05, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [113.37, -29.84],
                                        [0, 29.84],
                                        [-113.37, -29.84],
                                    ],
                                },
                                ix: 2,
                            },
                        },
                        {
                            ty: 'tm',
                            bm: 0,
                            hd: false,
                            mn: 'ADBE Vector Filter - Trim',
                            nm: 'Trim Paths 1',
                            ix: 2,
                            e: {
                                a: 1,
                                k: [
                                    {
                                        o: { x: 0.333, y: 0 },
                                        i: { x: 0, y: 1 },
                                        s: [100],
                                        t: 90,
                                    },
                                    {
                                        o: { x: 0.333, y: 0 },
                                        i: { x: 0.667, y: 1 },
                                        s: [51],
                                        t: 180,
                                    },
                                    { s: [100], t: 281 },
                                ],
                                ix: 2,
                            },
                            o: { a: 0, k: 0, ix: 3 },
                            s: {
                                a: 1,
                                k: [
                                    {
                                        o: { x: 0.333, y: 0 },
                                        i: { x: 0, y: 1 },
                                        s: [0],
                                        t: 90,
                                    },
                                    {
                                        o: { x: 0.333, y: 0 },
                                        i: { x: 0.667, y: 1 },
                                        s: [49],
                                        t: 180,
                                    },
                                    { s: [0], t: 281 },
                                ],
                                ix: 1,
                            },
                            m: 1,
                        },
                        {
                            ty: 'st',
                            bm: 0,
                            hd: false,
                            mn: 'ADBE Vector Graphic - Stroke',
                            nm: 'Stroke 1',
                            lc: 2,
                            lj: 1,
                            ml: 10,
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 11, ix: 5 },
                            c: { a: 0, k: [0, 0, 0], ix: 3 },
                        },
                        {
                            ty: 'tr',
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            sk: { a: 0, k: 0, ix: 4 },
                            p: { a: 0, k: [250, 346.88], ix: 2 },
                            r: { a: 0, k: 0, ix: 6 },
                            sa: { a: 0, k: 0, ix: 5 },
                            o: { a: 0, k: 100, ix: 7 },
                        },
                    ],
                },
                {
                    ty: 'gr',
                    bm: 0,
                    hd: false,
                    mn: 'ADBE Vector Group',
                    nm: 'Group 2',
                    ix: 2,
                    cix: 2,
                    np: 3,
                    it: [
                        {
                            ty: 'sh',
                            bm: 0,
                            hd: false,
                            mn: 'ADBE Vector Shape - Group',
                            nm: 'Path 1',
                            ix: 1,
                            d: 1,
                            ks: {
                                a: 0,
                                k: {
                                    c: false,
                                    i: [
                                        [0, 0],
                                        [0, -71.36],
                                        [7.84, -17.29],
                                    ],
                                    o: [
                                        [69.569, 6.82],
                                        [0, 20.21],
                                        [0, 0],
                                    ],
                                    v: [
                                        [-61.965, -96.765],
                                        [61.965, 40.075],
                                        [49.764, 96.765],
                                    ],
                                },
                                ix: 2,
                            },
                        },
                        {
                            ty: 'tm',
                            bm: 0,
                            hd: false,
                            mn: 'ADBE Vector Filter - Trim',
                            nm: 'Trim Paths 1',
                            ix: 2,
                            e: {
                                a: 1,
                                k: [
                                    {
                                        o: { x: 0.333, y: 0 },
                                        i: { x: 0, y: 1 },
                                        s: [100],
                                        t: 90,
                                    },
                                    {
                                        o: { x: 0.333, y: 0 },
                                        i: { x: 0.667, y: 1 },
                                        s: [51],
                                        t: 180,
                                    },
                                    { s: [100], t: 281 },
                                ],
                                ix: 2,
                            },
                            o: { a: 0, k: 0, ix: 3 },
                            s: {
                                a: 1,
                                k: [
                                    {
                                        o: { x: 0.333, y: 0 },
                                        i: { x: 0, y: 1 },
                                        s: [0],
                                        t: 90,
                                    },
                                    {
                                        o: { x: 0.333, y: 0 },
                                        i: { x: 0.667, y: 1 },
                                        s: [49],
                                        t: 180,
                                    },
                                    { s: [0], t: 281 },
                                ],
                                ix: 1,
                            },
                            m: 1,
                        },
                        {
                            ty: 'st',
                            bm: 0,
                            hd: false,
                            mn: 'ADBE Vector Graphic - Stroke',
                            nm: 'Stroke 1',
                            lc: 2,
                            lj: 1,
                            ml: 10,
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 11, ix: 5 },
                            c: { a: 0, k: [0, 0, 0], ix: 3 },
                        },
                        {
                            ty: 'tr',
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            sk: { a: 0, k: 0, ix: 4 },
                            p: { a: 0, k: [325.535, 199.145], ix: 2 },
                            r: { a: 0, k: 0, ix: 6 },
                            sa: { a: 0, k: 0, ix: 5 },
                            o: { a: 0, k: 100, ix: 7 },
                        },
                    ],
                },
                {
                    ty: 'gr',
                    bm: 0,
                    hd: false,
                    mn: 'ADBE Vector Group',
                    nm: 'Group 3',
                    ix: 3,
                    cix: 2,
                    np: 3,
                    it: [
                        {
                            ty: 'sh',
                            bm: 0,
                            hd: false,
                            mn: 'ADBE Vector Shape - Group',
                            nm: 'Path 1',
                            ix: 1,
                            d: 1,
                            ks: {
                                a: 0,
                                k: {
                                    c: false,
                                    i: [
                                        [0, 0],
                                        [0, 20.59],
                                        [-70.08, 6.32],
                                    ],
                                    o: [
                                        [-8.11, -17.53],
                                        [0, -71.72],
                                        [0, 0],
                                    ],
                                    v: [
                                        [-49.855, 97.3],
                                        [-62.495, 39.64],
                                        [62.495, -97.3],
                                    ],
                                },
                                ix: 2,
                            },
                        },
                        {
                            ty: 'tm',
                            bm: 0,
                            hd: false,
                            mn: 'ADBE Vector Filter - Trim',
                            nm: 'Trim Paths 1',
                            ix: 2,
                            e: {
                                a: 1,
                                k: [
                                    {
                                        o: { x: 0.333, y: 0 },
                                        i: { x: 0, y: 1 },
                                        s: [100],
                                        t: 90,
                                    },
                                    {
                                        o: { x: 0.333, y: 0 },
                                        i: { x: 0.667, y: 1 },
                                        s: [51],
                                        t: 180,
                                    },
                                    { s: [100], t: 281 },
                                ],
                                ix: 2,
                            },
                            o: { a: 0, k: 0, ix: 3 },
                            s: {
                                a: 1,
                                k: [
                                    {
                                        o: { x: 0.333, y: 0 },
                                        i: { x: 0, y: 1 },
                                        s: [0],
                                        t: 90,
                                    },
                                    {
                                        o: { x: 0.333, y: 0 },
                                        i: { x: 0.667, y: 1 },
                                        s: [49],
                                        t: 180,
                                    },
                                    { s: [0], t: 281 },
                                ],
                                ix: 1,
                            },
                            m: 1,
                        },
                        {
                            ty: 'st',
                            bm: 0,
                            hd: false,
                            mn: 'ADBE Vector Graphic - Stroke',
                            nm: 'Stroke 1',
                            lc: 2,
                            lj: 1,
                            ml: 10,
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 11, ix: 5 },
                            c: { a: 0, k: [0, 0, 0], ix: 3 },
                        },
                        {
                            ty: 'tr',
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            sk: { a: 0, k: 0, ix: 4 },
                            p: { a: 0, k: [174.995, 199.58], ix: 2 },
                            r: { a: 0, k: 0, ix: 6 },
                            sa: { a: 0, k: 0, ix: 5 },
                            o: { a: 0, k: 100, ix: 7 },
                        },
                    ],
                },
            ],
            ind: 1,
        },
    ],
    v: '5.5.7',
    fr: 60,
    op: 296,
    ip: 0,
    assets: [],
};
const whiteSpinner = {
    nm: 'Comp 1',
    ddd: 0,
    h: 500,
    w: 500,
    meta: { g: '@lottiefiles/toolkit-js 0.33.2' },
    layers: [
        {
            ty: 4,
            nm: 'Untitled-2 Outlines 2',
            sr: 1.5,
            st: 0,
            op: 4500,
            ip: 0,
            hd: false,
            ddd: 0,
            bm: 0,
            hasMask: false,
            ao: 0,
            ks: {
                a: { a: 0, k: [249.874, 238.486, 0], ix: 1 },
                s: { a: 0, k: [100, 100, 100], ix: 6 },
                sk: { a: 0, k: 0 },
                p: { a: 0, k: [266, 229, 0], ix: 2 },
                r: {
                    a: 1,
                    k: [
                        { o: { x: 0.333, y: 0 }, i: { x: 0, y: 1 }, s: [0], t: 0 },
                        {
                            o: { x: 0.333, y: 0 },
                            i: { x: 0, y: 1 },
                            s: [360],
                            t: 90,
                        },
                        { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [720], t: 180 },
                        { s: [0], t: 281 },
                    ],
                    ix: 10,
                },
                sa: { a: 0, k: 0 },
                o: { a: 0, k: 100, ix: 11 },
            },
            ef: [],
            shapes: [
                {
                    ty: 'gr',
                    bm: 0,
                    hd: false,
                    mn: 'ADBE Vector Group',
                    nm: 'Group 1',
                    ix: 1,
                    cix: 2,
                    np: 3,
                    it: [
                        {
                            ty: 'sh',
                            bm: 0,
                            hd: false,
                            mn: 'ADBE Vector Shape - Group',
                            nm: 'Path 1',
                            ix: 1,
                            d: 1,
                            ks: {
                                a: 0,
                                k: {
                                    c: false,
                                    i: [
                                        [0, 0],
                                        [47.05, 0],
                                        [24.79, 36.05],
                                    ],
                                    o: [
                                        [-24.79, 36.05],
                                        [-47.05, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [113.37, -29.84],
                                        [0, 29.84],
                                        [-113.37, -29.84],
                                    ],
                                },
                                ix: 2,
                            },
                        },
                        {
                            ty: 'tm',
                            bm: 0,
                            hd: false,
                            mn: 'ADBE Vector Filter - Trim',
                            nm: 'Trim Paths 1',
                            ix: 2,
                            e: {
                                a: 1,
                                k: [
                                    { o: { x: 0.333, y: 0 }, i: { x: 0, y: 1 }, s: [100], t: 90 },
                                    {
                                        o: {
                                            x: 0.333,
                                            y: 0,
                                        },
                                        i: { x: 0.667, y: 1 },
                                        s: [51],
                                        t: 180,
                                    },
                                    { s: [100], t: 281 },
                                ],
                                ix: 2,
                            },
                            o: { a: 0, k: 0, ix: 3 },
                            s: {
                                a: 1,
                                k: [
                                    { o: { x: 0.333, y: 0 }, i: { x: 0, y: 1 }, s: [0], t: 90 },
                                    {
                                        o: {
                                            x: 0.333,
                                            y: 0,
                                        },
                                        i: { x: 0.667, y: 1 },
                                        s: [49],
                                        t: 180,
                                    },
                                    { s: [0], t: 281 },
                                ],
                                ix: 1,
                            },
                            m: 1,
                        },
                        {
                            ty: 'st',
                            bm: 0,
                            hd: false,
                            mn: 'ADBE Vector Graphic - Stroke',
                            nm: 'Stroke 1',
                            lc: 2,
                            lj: 1,
                            ml: 10,
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 11, ix: 5 },
                            c: { a: 0, k: [1, 1, 1], ix: 3 },
                        },
                        {
                            ty: 'tr',
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            sk: { a: 0, k: 0, ix: 4 },
                            p: { a: 0, k: [250, 346.88], ix: 2 },
                            r: { a: 0, k: 0, ix: 6 },
                            sa: { a: 0, k: 0, ix: 5 },
                            o: { a: 0, k: 100, ix: 7 },
                        },
                    ],
                },
                {
                    ty: 'gr',
                    bm: 0,
                    hd: false,
                    mn: 'ADBE Vector Group',
                    nm: 'Group 2',
                    ix: 2,
                    cix: 2,
                    np: 3,
                    it: [
                        {
                            ty: 'sh',
                            bm: 0,
                            hd: false,
                            mn: 'ADBE Vector Shape - Group',
                            nm: 'Path 1',
                            ix: 1,
                            d: 1,
                            ks: {
                                a: 0,
                                k: {
                                    c: false,
                                    i: [
                                        [0, 0],
                                        [0, -71.36],
                                        [7.84, -17.29],
                                    ],
                                    o: [
                                        [69.569, 6.82],
                                        [0, 20.21],
                                        [0, 0],
                                    ],
                                    v: [
                                        [-61.965, -96.765],
                                        [61.965, 40.075],
                                        [49.764, 96.765],
                                    ],
                                },
                                ix: 2,
                            },
                        },
                        {
                            ty: 'tm',
                            bm: 0,
                            hd: false,
                            mn: 'ADBE Vector Filter - Trim',
                            nm: 'Trim Paths 1',
                            ix: 2,
                            e: {
                                a: 1,
                                k: [
                                    { o: { x: 0.333, y: 0 }, i: { x: 0, y: 1 }, s: [100], t: 90 },
                                    {
                                        o: {
                                            x: 0.333,
                                            y: 0,
                                        },
                                        i: { x: 0.667, y: 1 },
                                        s: [51],
                                        t: 180,
                                    },
                                    { s: [100], t: 281 },
                                ],
                                ix: 2,
                            },
                            o: { a: 0, k: 0, ix: 3 },
                            s: {
                                a: 1,
                                k: [
                                    { o: { x: 0.333, y: 0 }, i: { x: 0, y: 1 }, s: [0], t: 90 },
                                    {
                                        o: {
                                            x: 0.333,
                                            y: 0,
                                        },
                                        i: { x: 0.667, y: 1 },
                                        s: [49],
                                        t: 180,
                                    },
                                    { s: [0], t: 281 },
                                ],
                                ix: 1,
                            },
                            m: 1,
                        },
                        {
                            ty: 'st',
                            bm: 0,
                            hd: false,
                            mn: 'ADBE Vector Graphic - Stroke',
                            nm: 'Stroke 1',
                            lc: 2,
                            lj: 1,
                            ml: 10,
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 11, ix: 5 },
                            c: { a: 0, k: [1, 1, 1], ix: 3 },
                        },
                        {
                            ty: 'tr',
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            sk: { a: 0, k: 0, ix: 4 },
                            p: { a: 0, k: [325.535, 199.145], ix: 2 },
                            r: { a: 0, k: 0, ix: 6 },
                            sa: { a: 0, k: 0, ix: 5 },
                            o: { a: 0, k: 100, ix: 7 },
                        },
                    ],
                },
                {
                    ty: 'gr',
                    bm: 0,
                    hd: false,
                    mn: 'ADBE Vector Group',
                    nm: 'Group 3',
                    ix: 3,
                    cix: 2,
                    np: 3,
                    it: [
                        {
                            ty: 'sh',
                            bm: 0,
                            hd: false,
                            mn: 'ADBE Vector Shape - Group',
                            nm: 'Path 1',
                            ix: 1,
                            d: 1,
                            ks: {
                                a: 0,
                                k: {
                                    c: false,
                                    i: [
                                        [0, 0],
                                        [0, 20.59],
                                        [-70.08, 6.32],
                                    ],
                                    o: [
                                        [-8.11, -17.53],
                                        [0, -71.72],
                                        [0, 0],
                                    ],
                                    v: [
                                        [-49.855, 97.3],
                                        [-62.495, 39.64],
                                        [62.495, -97.3],
                                    ],
                                },
                                ix: 2,
                            },
                        },
                        {
                            ty: 'tm',
                            bm: 0,
                            hd: false,
                            mn: 'ADBE Vector Filter - Trim',
                            nm: 'Trim Paths 1',
                            ix: 2,
                            e: {
                                a: 1,
                                k: [
                                    { o: { x: 0.333, y: 0 }, i: { x: 0, y: 1 }, s: [100], t: 90 },
                                    {
                                        o: {
                                            x: 0.333,
                                            y: 0,
                                        },
                                        i: { x: 0.667, y: 1 },
                                        s: [51],
                                        t: 180,
                                    },
                                    { s: [100], t: 281 },
                                ],
                                ix: 2,
                            },
                            o: { a: 0, k: 0, ix: 3 },
                            s: {
                                a: 1,
                                k: [
                                    { o: { x: 0.333, y: 0 }, i: { x: 0, y: 1 }, s: [0], t: 90 },
                                    {
                                        o: {
                                            x: 0.333,
                                            y: 0,
                                        },
                                        i: { x: 0.667, y: 1 },
                                        s: [49],
                                        t: 180,
                                    },
                                    { s: [0], t: 281 },
                                ],
                                ix: 1,
                            },
                            m: 1,
                        },
                        {
                            ty: 'st',
                            bm: 0,
                            hd: false,
                            mn: 'ADBE Vector Graphic - Stroke',
                            nm: 'Stroke 1',
                            lc: 2,
                            lj: 1,
                            ml: 10,
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 11, ix: 5 },
                            c: { a: 0, k: [1, 1, 1], ix: 3 },
                        },
                        {
                            ty: 'tr',
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            sk: { a: 0, k: 0, ix: 4 },
                            p: { a: 0, k: [174.995, 199.58], ix: 2 },
                            r: { a: 0, k: 0, ix: 6 },
                            sa: { a: 0, k: 0, ix: 5 },
                            o: { a: 0, k: 100, ix: 7 },
                        },
                    ],
                },
            ],
            ind: 1,
        },
    ],
    v: '5.5.7',
    fr: 60,
    op: 296,
    ip: 0,
    assets: [],
};

const spinner = useTemplateRef('spinner');

onMounted(() => {
    bodymovin.loadAnimation({
        container: spinner.value,
        animationData: props.color === 'black' ? blackSpinner : whiteSpinner,
        renderer: 'svg',
        autoplay: true,
        loop: true,
    });
});
</script>

<template>
    <div
        ref="spinner"
        class="relative"
        :class="{
            'size-8': size === 'small',
            'h-15 w-15': size === 'medium',
            'h-20 w-20': size === 'large',
        }"
    />
</template>
