<script setup>
import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { useTicketStore } from '@/stores/ticket.js';

const ticketStore = useTicketStore();
</script>

<template>
    <TransitionRoot
        as="template"
        :show="ticketStore.active"
    >
        <Dialog
            as="div"
            static
            class="fixed inset-0 z-50 overflow-y-auto"
            :open="ticketStore.active"
            @close="ticketStore.close"
        >
            <div class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
                <TransitionChild
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <DialogOverlay class="bg-primary/75 fixed inset-0 transition-opacity" />
                </TransitionChild>

                <!-- eslint-disable @intlify/vue-i18n/no-raw-text -->
                <span
                    class="hidden sm:inline-block sm:h-screen sm:align-middle"
                    aria-hidden="true"
                >
                    &#8203;
                </span>
                <!-- eslint-enable @intlify/vue-i18n/no-raw-text -->

                <TransitionChild
                    as="div"
                    class="relative my-8 inline-block w-full overflow-hidden text-left align-middle transition-all sm:max-w-lg"
                    enter="ease-out duration-300"
                    enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enter-to="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100 translate-y-0 sm:scale-100"
                    leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <Transition
                        name="translate"
                        mode="out-in"
                    >
                        <component :is="ticketStore.component" />
                    </Transition>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>
