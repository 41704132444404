import { formatDate, isHoliday, isWeekday } from '@/utils/date';
import '@/plugins/day-js';
import dayjs from 'dayjs';

export const useBusinessHours = () => {
    const startHour = 9;
    const endHour = 17;
    const endMinute = 30;
    const replyHours = 4;

    const calculateStartEnd = (now) => {
        let start = dayjs(now).tz('Europe/Amsterdam').hour(startHour).minute(0).second(0).millisecond(0);
        let end = dayjs(now).tz('Europe/Amsterdam').hour(endHour).minute(endMinute).second(0).millisecond(0);

        return { start, end };
    };

    const isInsideHours = (hour, minute) => {
        return (hour > startHour && hour < endHour) || hour === startHour || (hour === endHour && minute <= endMinute);
    };

    const isInsideBusinessHours = (date = new dayjs()) => {
        return (
            isInsideHours(date.hour(), date.minute()) && isWeekday(date.day()) && !isHoliday(date.date(), date.month())
        );
    };

    const isBeforeBusinessHours = (date = new dayjs()) => {
        return date.hour() < startHour || (isWeekday(date.day()) && !isHoliday(date.date(), date.month()));
    };

    const isAfterBusinessHours = (date = new dayjs()) => {
        return (
            date.hour() > endHour ||
            (date.hour() === endHour &&
                date.minute() > endMinute &&
                isWeekday(date.day()) &&
                !isHoliday(date.date(), date.month()))
        );
    };

    // If within working hours, receive reply within 4 hours.
    // Otherwise tomorrow within working hours.
    const receiveReplyBy = (now = dayjs()) => {
        let { start, end } = calculateStartEnd(now);

        let inFourHours = now.add(4, 'hour');

        if (isInsideBusinessHours(now) && inFourHours.isBetween(start, end)) {
            return {
                time: formatDate(inFourHours, 'HH:mm'),
                date: 'today',
            };
        }

        if (now.isBefore(start)) {
            return {
                time: formatDate(start.add(4, 'hour'), 'HH:mm'),
                date: 'today',
            };
        }

        if (now.isAfter(end)) {
            return {
                time: formatDate(start.add(4, 'hour'), 'HH:mm'),
                date: 'tomorrow',
            };
        }

        let hoursOutsideBusinessHours = replyHours;
        let minutesOutsideBusinessHours = 0;

        // in this case, we are inside working hours but inFourHours is outside working hours OR we are outside working hours
        // tomorrow could be a holiday or weekend, so we need to check if it is a working day
        if (inFourHours.isAfter(end) && isWeekday(now.day()) && !isHoliday(now.date(), now.month())) {
            hoursOutsideBusinessHours = inFourHours.diff(end, 'hour');
            minutesOutsideBusinessHours = inFourHours.diff(end, 'minute') - hoursOutsideBusinessHours * 60;
        }

        let tomorrow = now.add(1, 'day');

        if (isWeekday(tomorrow.day()) && !isHoliday(tomorrow.date(), tomorrow.month())) {
            return {
                time: formatDate(
                    tomorrow
                        .hour(startHour)
                        .minute(0)
                        .second(0)
                        .millisecond(0)
                        .add(hoursOutsideBusinessHours, 'hour')
                        .add(minutesOutsideBusinessHours, 'minute'),
                    'HH:mm'
                ),
                date: 'tomorrow',
            };
        }

        let nextWorkingDay = tomorrow;

        while (!isWeekday(nextWorkingDay.day()) || isHoliday(nextWorkingDay.date(), nextWorkingDay.month())) {
            nextWorkingDay = nextWorkingDay.add(1, 'day');
        }

        return {
            time: formatDate(
                nextWorkingDay
                    .hour(startHour)
                    .minute(0)
                    .second(0)
                    .millisecond(0)
                    .add(hoursOutsideBusinessHours, 'hour')
                    .add(minutesOutsideBusinessHours, 'minute'),
                'HH:mm'
            ),
            date: 'next_working_day',
        };
    };

    return {
        startHour,
        endHour,
        endMinute,
        isInsideBusinessHours,
        isBeforeBusinessHours,
        isAfterBusinessHours,
        receiveReplyBy,
    };
};
