<script setup>
import CFTicketModalInner from '@/components/Ticket/CFTicketModalInner.vue';
import CFButton from '@/components/CFButton.vue';
import { useTicketStore } from '@/stores/ticket.js';
import CFFormInput from '@/components/CFFormInput.vue';
import { computed, reactive, ref } from 'vue';
import CFFormTextarea from '@/components/CFFormTextarea.vue';
import CFDropzone from '@/components/CFDropzone.vue';
import CFTicketAttachment from '@/components/Ticket/CFTicketAttachment.vue';
import CFDropzoneOverlay from '@/components/CFDropzoneOverlay.vue';
import DropzoneIcon from '@/components/icons/DropzoneIcon.vue';
import CFCircleBackButton from '@/components/CFCircleBackButton.vue';

const ticketStore = useTicketStore();

const fileInput = ref();
const form = reactive({
    title: '',
    message: '',
    department: 'support',
    attachments: [],
});

const createTicket = async () => {
    let ticket = { ...form };

    if (ticketStore.state.domain?.id) {
        ticket.product_type = 'domain';
        ticket.product_id = ticketStore.state.domain.id;
    }

    if (ticketStore.state.ssl?.id) {
        ticket.product_type = 'ssl-certificate';
        ticket.product_id = ticketStore.state.ssl.id;
    }

    await ticketStore.create(ticket);
};

const addAttachment = (files) => {
    form.attachments.push(files[0]);
    fileInput.value.value = '';
};

const removeAttachment = (index) => {
    form.attachments.splice(index, 1);
};

const relatedDomain = computed(() => {
    return ticketStore.state.domain
        ? ticketStore.state.domain.attributes.name + '.' + ticketStore.state.domain.attributes.tld
        : null;
});

const relatedSSL = computed(() => {
    return ticketStore.state.ssl ? ticketStore.state.ssl.attributes.subject_name : null;
});
</script>

<template>
    <CFTicketModalInner :title="$t('support.create_ticket_title')">
        <form
            method="post"
            @submit.prevent="createTicket"
        >
            <div class="flex flex-col gap-4">
                <CFFormInput
                    v-if="relatedDomain"
                    :model-value="relatedDomain"
                    :label="$t('support.related_product')"
                    position="inset"
                    readonly
                />

                <CFFormInput
                    v-if="relatedSSL"
                    :model-value="relatedSSL"
                    :label="$t('support.related_product')"
                    position="inset"
                    readonly
                />

                <CFFormInput
                    v-model="form.title"
                    :label="$t('support.title_label')"
                    :placeholder="$t('support.title_placeholder')"
                    position="inset"
                    :loading="ticketStore.isLoading"
                    :errors="ticketStore.errors.hasType('title') || ticketStore.errors.hasData('title')"
                />

                <CFFormTextarea
                    v-model="form.message"
                    :label="$t('support.message_label')"
                    :placeholder="$t('support.message_placeholder')"
                    :loading="ticketStore.isLoading"
                    :invalid="ticketStore.errors.hasType('message') || ticketStore.errors.hasData('message')"
                />
            </div>

            <h3 class="mt-6 text-base font-bold">
                {{ $t('support.add_image') }}
            </h3>
            <p class="mt-2 mb-4 text-sm">
                {{ $t('support.add_image_description') }}
            </p>

            <CFDropzone
                v-if="form.attachments.length < 5"
                v-slot="{ active }"
                class="bg-accent-200/20 hover:bg-accent-300/30 relative mb-4 cursor-pointer p-4 transition duration-150 ease-in"
                @files-dropped="addAttachment"
                @click="fileInput.click()"
            >
                <CFDropzoneOverlay :visible="active" />

                <input
                    ref="fileInput"
                    type="file"
                    class="hidden"
                    accept="image/png, image/jpeg, image/jpg"
                    @change="addAttachment($event.target.files)"
                />

                <div
                    class="border-accent text-accent flex flex-col items-center justify-center gap-4 border border-dashed p-6 text-sm"
                >
                    <DropzoneIcon class="h-20 w-20" />
                    <div class="flex gap-1">
                        <span>{{ $t('support.drop_files_here_or') }}</span>
                        <span class="lowercase underline underline-offset-3">
                            {{ $t('button.select_file') }}
                        </span>
                    </div>
                </div>
            </CFDropzone>

            <div
                v-if="form.attachments.length > 0"
                class="divide-neutrals-400 border-neutrals-400 divide-y border-y"
            >
                <CFTicketAttachment
                    v-for="(attachment, index) in form.attachments"
                    :key="index"
                    :file="attachment"
                    @remove="removeAttachment(index)"
                />
            </div>
        </form>

        <template #actions>
            <CFCircleBackButton @click="ticketStore.goToPrevious()">
                {{ $t('button.back') }}
            </CFCircleBackButton>

            <CFButton
                type="button"
                :loading="ticketStore.isLoading"
                @click="createTicket"
            >
                {{ $t('button.ask_question') }}
            </CFButton>
        </template>
    </CFTicketModalInner>
</template>
