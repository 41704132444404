<script setup>
import { DialogTitle } from '@headlessui/vue';
import { useTicketStore } from '@/stores/ticket.js';
import { useSlots } from 'vue';
import CFCircleCloseButton from '@/components/CFCircleCloseButton.vue';

defineProps({
    title: {
        type: String,
        required: true,
    },
});

const ticketStore = useTicketStore();
const slots = useSlots();
</script>

<template>
    <div class="bg-grey-light relative w-full px-7 pt-7 pb-6 text-left shadow-xl">
        <div>
            <div class="mb-4">
                <DialogTitle
                    as="h3"
                    class="text-base font-bold text-black"
                >
                    {{ title }}
                </DialogTitle>
                <CFCircleCloseButton
                    class="absolute top-4 right-4"
                    @click.prevent="ticketStore.close()"
                />
            </div>

            <slot />
        </div>

        <div
            v-if="!!slots.actions"
            class="mt-6 flex justify-between gap-3 border-t border-black pt-5"
        >
            <slot name="actions" />
        </div>
    </div>
</template>
