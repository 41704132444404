<template>
    <svg
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        width="24"
    >
        <path
            d="m1.5 0.498 0 7 7 0"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        ></path>
        <path
            d="M1.5 7.5a11.656 11.656 0 0 1 16.179 -2.647 11.508 11.508 0 0 1 0.11 18.645"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        ></path>
    </svg>
</template>
