<script setup>
import { useTemplateRef } from 'vue';

defineProps({
    name: {
        type: String,
        required: true,
    },
    type: {
        type: String,
        default: 'text',
    },
    placeholder: {
        type: String,
        default: undefined,
    },
    autocomplete: {
        type: String,
        default: 'off',
    },
    errors: {
        type: [Number, String, Boolean],
        default: undefined,
    },
    pattern: {
        type: String,
        default: undefined,
    },
    inputMode: {
        type: String,
        default: undefined,
    },
});

const modelValue = defineModel({
    type: String,
    required: true,
});

const inputRef = useTemplateRef('inputRef');

defineExpose({
    focus: () => inputRef.value?.focus(),
});
</script>

<template>
    <div class="relative mb-8">
        <label
            :for="name"
            class="sr-only"
        >
            {{ placeholder }}
        </label>
        <input
            ref="inputRef"
            v-model="modelValue"
            v-bind="$attrs"
            class="placeholder-primary/40 focus:border-primary block w-full border-0 border-b bg-transparent px-0 leading-10 focus:bg-transparent focus:ring-transparent focus:outline-hidden lg:text-2xl xl:text-3xl"
            :class="[!!errors ? 'border-warning' : 'border-primary/60']"
            :name="name"
            :type="type"
            :placeholder="placeholder"
            :autocomplete="autocomplete"
            :value="modelValue"
            :pattern="pattern"
            :inputmode="inputMode"
        />
    </div>
</template>
