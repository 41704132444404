<script setup>
import { useTicketStore } from '@/stores/ticket.js';
import HeadphonesQuestionIcon from '@/components/icons/HeadphonesQuestionIcon.vue';
import { Tooltip as VueTooltip } from 'floating-vue';
import CFKbd from '@/components/CFKbd.vue';
import { defineShortcuts } from '@/composables/defineShortcuts.js';
import CFRoundedButton from '@/components/ui/CFRoundedButton.vue';

const ticketStore = useTicketStore();

defineShortcuts({
    shift_h: {
        handler: () => {
            if (ticketStore.active) {
                ticketStore.close();
            } else {
                ticketStore.open();
            }
        },
    },
});
</script>

<template>
    <VueTooltip theme="dark-tooltip">
        <CFRoundedButton
            :icon="HeadphonesQuestionIcon"
            color="primary"
            size="lg"
            @click="ticketStore.open()"
        />

        <template #popper>
            <div class="flex flex-col items-center">
                <strong>{{ $t('shortcuts.support') }}</strong>
                <CFKbd
                    value="Shift + H"
                    class="bg-primary text-neutrals-600 ring-primary"
                />
            </div>
        </template>
    </VueTooltip>
</template>
