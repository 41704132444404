<script setup>
import CFProductBlockCol from '@/components/Dashboard/CFProductBlockCol.vue';
import { useDomainsStore } from '@/stores/domains.js';
import { useQuery } from '@tanstack/vue-query';

const domainStore = useDomainsStore();

const domainsQuery = useQuery({
    queryKey: ['domains-count'],
    queryFn: async () => {
        const { data } = await domainStore.getDomains();

        return data.meta.total;
    },
    refetchOnWindowFocus: false,
});
</script>

<template>
    <CFProductBlockCol
        :name="$t('dashboard.domains')"
        :amount="domainsQuery.data.value ?? 0"
        :loading="domainsQuery.isPending.value"
        href="domains"
        order="new domain"
    />
</template>
