import { shallowRef } from 'vue';
import { modalInjectionKey } from '@/composables/useModal.js';

export default {
    install: (app) => {
        const modalState = shallowRef({
            component: 'div',
            props: {},
        });

        app.provide(modalInjectionKey, modalState);
    },
};
