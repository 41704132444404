<script setup>
import CFLockedTeamBanner from '@/components/CFLockedTeamBanner.vue';
import CFUnverifiedTeamBanner from '@/components/CFUnverifiedTeamBanner.vue';
import CFRequired2FABanner from '@/components/CFRequired2FABanner.vue';
import { useTeamStore } from '@/stores/team.js';
import { useAccountStatus } from '@/composables/useAccountStatus.js';
import { computed, onMounted, useTemplateRef } from 'vue';

const teamStore = useTeamStore();
const { shouldEnforceTFA } = useAccountStatus();
const header = useTemplateRef('header');

const showHeader = computed(() => {
    return shouldEnforceTFA || (teamStore.hasRequiredInformation && (!teamStore.isVerified || !teamStore.isUnlocked));
});

onMounted(() => {
    if (!showHeader.value || !header.value) {
        return;
    }

    // Adjust the padding of the cyberfusion wrapper to account for the header height
    document.getElementById('cyberfusion').style.paddingTop = `${header.value.clientHeight}px`;
});
</script>

<template>
    <header
        v-if="showHeader"
        ref="header"
        class="fixed top-0 right-0 left-0 z-100 w-full"
    >
        <CFRequired2FABanner v-if="shouldEnforceTFA" />
        <CFUnverifiedTeamBanner v-else-if="teamStore.hasRequiredInformation && !teamStore.isVerified" />
        <CFLockedTeamBanner v-else-if="teamStore.hasRequiredInformation && !teamStore.isUnlocked" />
    </header>
</template>
