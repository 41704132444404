import { tv } from 'tailwind-variants';

export default tv({
    slots: {
        base: 'relative z-1 inline-flex cursor-pointer items-center justify-center rounded-full border bg-transparent transition-all duration-200 ease-in-out [*:svg]:stroke-current hover:[&>*:last-child]:scale-100',
        before: 'bg-primary absolute inset-0 -z-20 h-full w-full rounded-full',
        indicator: 'bg-pink-gradient absolute top-0 -right-1 size-4 rounded-full',
        icon: 'transition-all duration-200 ease-in-out',
        after: 'bg-secondary absolute inset-0 -z-10 h-full w-full scale-0 rounded-full transition-all duration-200 ease-in-out',
    },
    variants: {
        color: {
            default: '',
            primary: '',
            secondary: '',
        },
        size: {
            md: '',
            lg: '',
        },
    },
    compoundVariants: [
        {
            color: 'default',
            class: {
                base: 'text-primary hover:border-primary hover:bg-primary hover:text-primary border-transparent',
                before: 'invisible',
            },
        },
        {
            color: 'primary',
            class: 'border-primary text-secondary hover:text-primary',
        },
        {
            color: 'secondary',
            class: {
                base: 'border-primary text-primary hover:border-primary hover:text-secondary hover:[&>*:last-child]:bg-primary',
                before: 'invisible',
            },
        },
        {
            size: 'md',
            class: {
                base: 'size-8',
                icon: 'size-4',
            },
        },
        {
            size: 'lg',
            class: {
                base: 'size-12',
                icon: 'size-6',
            },
        },
    ],
    defaultVariants: {
        size: 'md',
        invert: false,
    },
});
