import { createRouter, createWebHistory } from 'vue-router';
import Dashboard from '@/views/Dashboard.vue';
import Login from '@/views/auth/Login.vue';
import { useAppStore } from '@/stores/app.js';
import { refresh } from '@/services/auth.js';
import { hydrate } from '@/services/hydrate.js';
import { useUserStore } from '@/stores/user.js';
import { usePermissionsStore } from '@/stores/permissions.js';
import enforceTwoFactor from '@/router/middleware/enforceTwoFactor.js';
import middlewarePipeline from '@/router/middleware/middlewarePipeline.js';
import redirectIfAuthenticated from '@/router/middleware/redirectIfAuthenticated.js';
import shouldBeAuthenticated from '@/router/middleware/shouldBeAuthenticated.js';
import ensureUserIsVerified from '@/router/middleware/ensureUserIsVerified.js';
import { useTeamStore } from '@/stores/team.js';
import { UserRole } from '@/constants/roles.js';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        // Auth routes
        {
            path: '/login',
            name: 'login',
            component: Login,
            meta: {
                middleware: [redirectIfAuthenticated],
            },
        },
        {
            path: '/admin',
            redirect: '/admin/login',
        },
        {
            path: '/admin/login',
            name: 'admin login',
            component: () => import('@/views/auth/AdminLogin.vue'),
            meta: {
                middleware: [redirectIfAuthenticated],
            },
        },
        {
            path: '/logout',
            name: 'logout',
            component: () => import('@/views/auth/Logout.vue'),
            meta: {
                middleware: [shouldBeAuthenticated],
            },
        },
        {
            path: '/password-forgot',
            name: 'password forgot',
            component: () => import('@/views/auth/PasswordForgot.vue'),
            meta: {
                middleware: [redirectIfAuthenticated],
            },
        },
        {
            path: '/password/reset/:token',
            name: 'password reset',
            component: () => import('@/views/auth/PasswordReset.vue'),
            meta: {
                middleware: [redirectIfAuthenticated],
            },
        },
        {
            path: '/sign-up',
            name: 'sign up',
            component: () => import('@/views/auth/SignUp.vue'),
            meta: {
                middleware: [redirectIfAuthenticated],
            },
        },
        {
            path: '/email/confirm/:token',
            name: 'confirm email',
            component: () => import('@/views/auth/ConfirmEmail.vue'),
        },
        {
            path: '/registration/complete',
            name: 'complete registration',
            component: () => import('@/views/CompleteRegistration.vue'),
            meta: {
                middleware: [shouldBeAuthenticated],
            },
        },
        {
            path: '/user/confirm/:token',
            name: 'confirm account',
            component: () => import('@/views/auth/ConfirmAccount.vue'),
        },
        {
            path: '/tfa-setup',
            name: 'tfa setup',
            component: () => import('@/views/auth/TwoFactorSetup.vue'),
            meta: {
                middleware: [shouldBeAuthenticated],
            },
        },

        // Dashboard routes
        {
            path: '/',
            name: 'dashboard',
            component: Dashboard,
            meta: {
                middleware: [shouldBeAuthenticated, enforceTwoFactor, ensureUserIsVerified],
            },
        },

        // Domains
        {
            path: '/domains',
            meta: {
                permissions: ['manage domains'],
                middleware: [shouldBeAuthenticated, enforceTwoFactor, ensureUserIsVerified],
            },
            children: [
                {
                    path: '',
                    name: 'domains',
                    component: () => import('@/views/domains/index.vue'),
                },
                {
                    path: '/domain/:domain',
                    name: 'single domain',
                    component: () => import('@/views/domains/show.vue'),
                },
                {
                    path: '/domain/:domain/dns',
                    name: 'domain dns',
                    component: () => import('@/views/domains/dns.vue'),
                },
                {
                    path: '/domain/:domain/nameservers',
                    name: 'domain nameservers',
                    component: () => import('@/views/domains/nameservers.vue'),
                    meta: {
                        permissions: ['manage domains'],
                    },
                },
                {
                    path: '/domain/:domain/activity-log',
                    name: 'domain activity log',
                    component: () => import('@/views/domains/activity-log.vue'),
                    meta: {
                        permissions: ['manage domains'],
                        roles: [UserRole.Developer, UserRole.TeamAdmin, UserRole.SuperUser],
                    },
                },
            ],
        },

        // SSL certificates
        {
            path: '/ssl',
            meta: {
                permissions: ['manage ssl-certificates'],
                middleware: [shouldBeAuthenticated, enforceTwoFactor, ensureUserIsVerified],
            },
            children: [
                {
                    path: '',
                    name: 'ssl',
                    component: () => import('@/views/ssl/index.vue'),
                },
                {
                    path: ':ssl',
                    name: 'single ssl',
                    component: () => import('@/views/ssl/show.vue'),
                },
            ],
        },

        // Invoices
        {
            path: '/invoices',
            name: 'invoices',
            component: () => import('@/views/Invoices.vue'),
            meta: {
                permissions: ['manage financial'],
                middleware: [shouldBeAuthenticated, enforceTwoFactor, ensureUserIsVerified],
            },
        },

        // Team
        {
            path: '/team',
            meta: {
                middleware: [shouldBeAuthenticated, ensureUserIsVerified],
            },
            children: [
                {
                    path: '',
                    name: 'team',
                    component: () => import('@/views/team/index.vue'),
                },
                {
                    path: 'edit',
                    name: 'edit team',
                    component: () => import('@/views/team/edit.vue'),
                    meta: {
                        permissions: ['manage teams'],
                        middleware: [enforceTwoFactor],
                    },
                },
                {
                    path: 'activity-log',
                    name: 'team activity log',
                    component: () => import('@/views/team/activity-log.vue'),
                    meta: {
                        permissions: ['manage teams'],
                        middleware: [enforceTwoFactor],
                    },
                },
            ],
        },

        // Support
        {
            path: '/support',
            meta: {
                permissions: ['manage support-messages'],
                middleware: [shouldBeAuthenticated, enforceTwoFactor, ensureUserIsVerified],
            },
            children: [
                {
                    path: '',
                    name: 'support',
                    component: () => import('@/views/support/index.vue'),
                },
                {
                    path: 'migration',
                    name: 'migration',
                    component: () => import('@/views/support/MigrationTypeSelect.vue'),
                },
                {
                    path: 'migration/start',
                    name: 'migration-start',
                    component: () => import('@/views/support/MigrationOrder.vue'),
                },
                {
                    path: 'migration/control-panel',
                    name: 'migration-control-panel',
                    component: () => import('@/views/support/MigrationOrder.vue'),
                },
                {
                    path: 'migration/mailbox',
                    name: 'migration-mailbox',
                    component: () => import('@/views/support/MigrationOrder.vue'),
                },
            ],
        },

        // Contacts and companies
        {
            path: '/contacts',
            meta: {
                permissions: ['manage teams'],
                middleware: [shouldBeAuthenticated, enforceTwoFactor, ensureUserIsVerified],
            },
            children: [
                // Contacts
                {
                    path: '',
                    name: 'contacts',
                    component: () => import('@/views/contacts/index.vue'),
                },
                {
                    path: '/contact/new',
                    name: 'new contact',
                    component: () => import('@/views/contacts/contact/create.vue'),
                },
                {
                    path: '/contact/:contact/edit',
                    name: 'edit contact',
                    component: () => import('@/views/contacts/contact/edit.vue'),
                },
                {
                    path: '/contact/:contact',
                    name: 'single contact',
                    component: () => import('@/views/contacts/contact/show.vue'),
                },
                // Companies
                {
                    path: '/company/new',
                    name: 'new company',
                    component: () => import('@/views/contacts/company/create.vue'),
                },
                {
                    path: '/company/:company/edit',
                    name: 'edit company',
                    component: () => import('@/views/contacts/company/edit.vue'),
                },
                {
                    path: '/company/:company',
                    name: 'single company',
                    component: () => import('@/views/contacts/company/show.vue'),
                },
            ],
        },

        // User settings
        {
            path: '/settings',
            name: 'settings',
            component: () => import('@/views/Settings.vue'),
            meta: {
                middleware: [shouldBeAuthenticated],
            },
        },

        // Order routes
        {
            path: '/order',
            meta: {
                roles: [UserRole.Developer, UserRole.TeamAdmin, UserRole.SuperUser],
                middleware: [shouldBeAuthenticated, enforceTwoFactor, ensureUserIsVerified],
            },
            children: [
                {
                    path: '',
                    name: 'new product',
                    component: () => import('@/views/order/product/Product.vue'),
                },
                {
                    path: 'domain',
                    name: 'new domain',
                    component: () => import('@/views/order/domain/Domain.vue'),
                },
                {
                    path: 'ssl',
                    name: 'new ssl',
                    component: () => import('@/views/order/ssl/SSL.vue'),
                },
            ],
        },

        // Error routes
        {
            path: '/unauthorized',
            name: 'unauthorized',
            component: () => import('@/views/errors/Unauthorized.vue'),
            meta: {
                middleware: [shouldBeAuthenticated],
            },
        },

        // Redirect all unmatched routes
        { path: '/:pathMatch(.*)*', redirect: '/' },
    ],
});

let firstLoad = true;

router.beforeEach(async (to, from) => {
    const appStore = useAppStore();
    const userStore = useUserStore();
    const teamStore = useTeamStore();
    const permissions = usePermissionsStore();

    if (firstLoad) {
        firstLoad = false;

        // Check if access token is expired when authenticated
        if (appStore.authenticated && appStore.accessTokenExpiry < Date.now()) {
            // Try retrieving a fresh access token on first load
            try {
                await refresh({ navigate: false });
            } catch {
                // Ignore error
            }
        }
    }

    // Ensure the app is hydrated if authenticated
    if (appStore.hydrated === false) {
        appStore.hydrating = false;

        if (appStore.authenticated === true) {
            await hydrate();
        }
    }

    // Check roles
    if (to.meta?.roles && !userStore.hasRole(to.meta.roles)) {
        return { name: 'unauthorized' };
    }

    // Check permissions
    if (to.meta?.permissions && !permissions.hasAllPermissions(to.meta.permissions)) {
        return { name: 'unauthorized' };
    }

    // Check middleware
    if (!to.meta?.middleware) {
        return true;
    }

    const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware];
    const context = {
        to,
        from,
        redirect: (to) => router.push(to),
        authenticated: appStore.authenticated,
        userIsVerified: userStore.isVerified,
        shouldEnforce2FA: teamStore.enforces2FA && !userStore.has2FA,
        hasRequiredTeamFields: teamStore.hasRequiredInformation,
        teamIsVerified: teamStore.isVerified,
        teamIsUnlocked: teamStore.isUnlocked,
    };

    return middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1),
    });
});

router.onError((error, to) => {
    if (!to.query?.eraseCache) {
        if (to.fullPath) {
            window.location = location.protocol + '//' + location.host + to.fullPath + '?eraseCache=true';
        } else {
            window.location = location.protocol + '//' + location.host + '?eraseCache=true';
        }
    }
});

export default router;
