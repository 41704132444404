<script setup>
import CFNotification from '@/components/CFNotification.vue';
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { useNotificationsStore } from '@/stores/notifications';
import CFButton from '@/components/CFButton.vue';
import { toRef } from 'vue';
import dayjs from 'dayjs';
import { formatDate } from '@/utils/date';
import CFIntersectionTrigger from '@/components/CFIntersectionTrigger.vue';

const notificationsStore = useNotificationsStore();

const selectedNotification = toRef(notificationsStore, 'selectedNotification');

const onAction = (url) => {
    window.open(url, '_self');
};
</script>

<template>
    <TransitionRoot
        as="template"
        :show="notificationsStore.isOpen"
    >
        <Dialog
            as="div"
            class="fixed inset-0 z-80 overflow-hidden"
            @close="notificationsStore.close()"
        >
            <TransitionChild
                as="template"
                enter="ease-in-out duration-500"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in-out duration-500"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <DialogOverlay class="absolute inset-0" />
            </TransitionChild>
            <div class="pointer-events-none fixed inset-y-0 right-0 z-80 flex pl-7">
                <TransitionChild
                    as="template"
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enter-from="translate-x-full"
                    enter-to="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leave-from="translate-x-0"
                    leave-to="translate-x-full"
                >
                    <div
                        id="notifications-sidebar"
                        class="pointer-events-auto relative"
                    >
                        <TransitionChild
                            as="template"
                            enter="ease-in-out duration-500"
                            enter-from="opacity-0"
                            enter-to="opacity-100"
                            leave="ease-in-out duration-500"
                            leave-from="opacity-100"
                            leave-to="opacity-0"
                        >
                            <button
                                type="button"
                                class="bg-primary absolute top-[33px] z-10 flex h-[51px] w-[51px] cursor-pointer items-center justify-center rounded-full focus:outline-hidden"
                                :class="[notificationsStore.hasOpenNotification ? '-left-[18px]' : '-left-[25px]']"
                                @click="notificationsStore.close()"
                            >
                                <svg
                                    viewBox="-0.5 -0.5 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="20"
                                    width="20"
                                >
                                    <path
                                        d="m0.4791666666666667 0.47820833333333335 22.041666666666668 22.041666666666668"
                                        fill="none"
                                        stroke="white"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="1"
                                    ></path>
                                    <path
                                        d="m22.520833333333336 0.47820833333333335 -22.041666666666668 22.041666666666668"
                                        fill="none"
                                        stroke="white"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="1"
                                    ></path>
                                </svg>
                            </button>
                        </TransitionChild>

                        <div class="flex h-full">
                            <div
                                class="relative flex h-full w-[333px] flex-col overflow-y-auto bg-black pt-[50px] text-white"
                                :class="{ '-right-2': selectedNotification?.id }"
                            >
                                <DialogTitle class="mx-8 mb-11 w-[240px] text-base leading-none font-bold">
                                    {{ $t('dashboard.notifications') }}
                                </DialogTitle>
                                <div class="relative flex-1">
                                    <CFNotification
                                        v-for="notification in notificationsStore.notifications"
                                        :key="notification.id"
                                        :notification="notification"
                                        color="secondary"
                                        :selected="selectedNotification"
                                        @view-notification="notificationsStore.show(notification)"
                                    />
                                    <CFIntersectionTrigger @intersected="notificationsStore.loadMore" />
                                </div>
                            </div>

                            <div
                                v-show="notificationsStore.hasOpenNotification"
                                class="relative h-full overflow-y-auto bg-black"
                                :class="[notificationsStore.hasOpenNotification ? 'w-[460px]' : 'w-0']"
                            >
                                <template v-if="selectedNotification?.attributes">
                                    <div class="bg-neutrals-300 mt-[109px] w-[460px] px-7 pt-9 pb-[37px]">
                                        <div class="border-b border-b-black bg-white px-[29px] py-[34px]">
                                            <h2 class="mb-1 text-3xl leading-7.5 break-words">
                                                {{ selectedNotification.attributes?.subject }}
                                            </h2>
                                            <div class="mb-5">
                                                <span
                                                    v-tooltip="{
                                                        theme: 'light-tooltip',
                                                        content: dayjs(
                                                            selectedNotification.attributes?.notified_at
                                                        ).format('DD-MM-YYYY HH:mm'),
                                                    }"
                                                    class="py-1 text-xs"
                                                >
                                                    {{
                                                        dayjs().to(dayjs(selectedNotification.attributes?.notified_at))
                                                    }},
                                                    {{
                                                        formatDate(
                                                            selectedNotification.attributes?.notified_at,
                                                            'HH:mm'
                                                        )
                                                    }}
                                                </span>
                                            </div>

                                            <div
                                                class="notification-message whitespace-pre-line"
                                                v-html="selectedNotification.attributes?.message"
                                            ></div>
                                        </div>
                                        <div
                                            v-if="selectedNotification.attributes?.actions.primary"
                                            class="mt-[22px] flex justify-end space-x-4"
                                        >
                                            <CFButton
                                                v-if="selectedNotification.attributes?.actions.secondary"
                                                color="outline"
                                                size="large"
                                                @click="onAction(selectedNotification.attributes.actions.secondary.url)"
                                            >
                                                {{ selectedNotification.attributes?.actions.secondary.label }}
                                            </CFButton>
                                            <CFButton
                                                v-if="selectedNotification.attributes?.actions.primary"
                                                color="primary"
                                                size="large"
                                                @click="onAction(selectedNotification.attributes.actions.primary.url)"
                                            >
                                                {{ selectedNotification.attributes.actions.primary.label }}
                                            </CFButton>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<style scoped>
@reference "@css/app.css";

.notification-message:deep(ul),
.notification-message:deep(ol),
.notification-message:deep(p),
.notification-message:deep(blockquote) {
    @apply mb-4;
}

.notification-message:deep(p:last-of-type) {
    @apply m-0;
}

.notification-message:deep(a) {
    @apply text-accent underline underline-offset-3;
}

.notification-message:deep(ul) {
    @apply ml-3 list-disc;
}

.notification-message:deep(ol) {
    @apply ml-4 list-decimal;
}

.notification-message:deep(strong) {
    @apply font-bold;
}

.notification-message:deep(blockquote) {
    @apply border-primary bg-neutrals-300 border-l-4 py-1 pl-4;

    p:last-of-type {
        @apply m-0;
    }
}

.notification-message:deep(h1),
.notification-message:deep(h2),
.notification-message:deep(h3),
.notification-message:deep(h4),
.notification-message:deep(h5) {
    @apply mb-1;
}

.notification-message:deep(h1) {
    @apply text-3xl font-normal lg:text-[40px] lg:leading-10 2xl:text-5xl;
}

.notification-message:deep(h2) {
    @apply text-xl font-normal lg:text-2xl 2xl:text-3xl;
}

.notification-message:deep(h3) {
    @apply text-lg font-normal lg:text-xl 2xl:text-2xl;
}

.notification-message:deep(h4) {
    @apply text-base font-normal lg:text-lg 2xl:text-xl;
}

.notification-message:deep(h5) {
    @apply text-sm font-normal lg:text-base 2xl:text-lg;
}
</style>
