<script setup>
import CFNavigationSidebar from '@/components/CFNavigationSidebar.vue';
import CFNotificationsSidebar from '@/components/CFNotificationsSidebar.vue';
import CFSearchModal from '@/components/Search/CFSearchModal.vue';
import CFNotificationBell from '@/components/CFNotificationBell.vue';
import CFDialog from '@/components/CFDialog.vue';
import CFTicketModal from '@/components/Ticket/CFTicketModal.vue';
import CFSupportCircle from '@/components/CFSupportCircle.vue';
import CFSearchCircle from '@/components/CFSearchCircle.vue';
import { useAccountStatus } from '@/composables/useAccountStatus.js';
import CFBanners from '@/views/layouts/components/CFBanners.vue';

defineProps({
    toggle: {
        type: Boolean,
        default: false,
    },
});

const { hasFullAccess } = useAccountStatus();
</script>

<template>
    <div class="flex h-full w-full overflow-hidden">
        <CFBanners />

        <CFNavigationSidebar />

        <slot name="sidebar" />

        <main
            class="main-content"
            :class="toggle ? 'with-sidebar' : 'no-sidebar'"
        >
            <div
                v-if="hasFullAccess"
                id="actionButtons"
                class="absolute top-8 right-8 flex items-center gap-4"
            >
                <CFSearchCircle />
                <CFSupportCircle />
                <CFNotificationBell />
            </div>

            <slot />
        </main>

        <CFSearchModal />

        <CFNotificationsSidebar />

        <CFDialog />
        <CFTicketModal />
    </div>
</template>
