<script setup>
import XIcon from '@/components/icons/XIcon.vue';
import CFRoundedButton from '@/components/ui/CFRoundedButton.vue';
import { h } from 'vue';

const icon = () => {
    return h(XIcon, {
        strokeWidth: 2,
    });
};
</script>

<template>
    <CFRoundedButton
        :icon="icon"
        color="default"
    />
</template>
