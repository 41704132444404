<script setup>
import CFNotification from '@/components/CFNotification.vue';
import { useNotificationsStore } from '@/stores/notifications.js';
import { computed } from 'vue';

const notificationsStore = useNotificationsStore();

const filterNotifications = computed(() => {
    return [...notificationsStore.notifications.slice(0, 3)];
});
</script>

<template>
    <div class="mb-12">
        <h2 class="mb-4 text-base font-bold">{{ $t('dashboard.notifications') }}</h2>
        <div class="flex flex-col justify-between gap-x-2 space-y-15 lg:flex-row lg:space-y-0 lg:space-x-4">
            <CFNotification
                v-for="notification in filterNotifications"
                :key="notification.id"
                :notification="notification"
                color="primary"
                @view-notification="notificationsStore.show(notification)"
            />
        </div>
    </div>
</template>
