<script setup>
import { computed } from 'vue';
import button from '@/theme/rounded-button.js';

const props = defineProps({
    icon: {
        type: [Object, Function],
        required: true,
    },
    color: {
        type: String,
        default: 'default',
        validator: (v) => ['default', 'primary', 'secondary'].includes(v),
    },
    size: {
        type: String,
        default: 'md',
        validator: (v) => ['md', 'lg'].includes(v),
    },
    indicator: {
        type: Boolean,
        default: false,
    },
    class: {
        type: [String, Array, Object],
        default: '',
    },
});

const ui = computed(() =>
    button({
        color: props.color,
        size: props.size,
    })
);
</script>

<template>
    <button
        type="button"
        :class="ui.base({ class: props.class })"
    >
        <span
            :class="ui.before()"
            aria-hidden="true"
        />
        <span
            v-if="indicator"
            :class="ui.indicator()"
        />
        <component
            :is="icon"
            :class="ui.icon()"
        />
        <span
            :class="ui.after()"
            aria-hidden="true"
        />
    </button>
</template>
