import { useDateFormat } from '@vueuse/core';
import i18n from '@/lang/i18n.js';
import { unref } from 'vue';

export const formatDate = (date, format = 'DD-MM-YYYY') => {
    let locale = 'nl-NL';

    switch (i18n.global.locale.value) {
        case 'en':
            locale = 'en-US';
            break;
    }

    return unref(useDateFormat(date, format, { locales: locale }));
};

// TODO: move to config
const holidays = [
    [1, 1], // New Year's Day
    [25, 12], // Christmas Day
    [26, 12], // Boxing Day
];

export const isHoliday = (day, month) => holidays.some(([hDay, hMonth]) => hDay === day && hMonth === month + 1);

export const isWeekday = (weekday) => ![0, 6].includes(weekday);
