<script setup>
import CFTicketModalInner from '@/components/Ticket/CFTicketModalInner.vue';
import CFButton from '@/components/CFButton.vue';
import { useTicketStore } from '@/stores/ticket.js';
import CreateTicketStep from '@/components/Ticket/Steps/CreateTicketStep.vue';
import { shallowRef } from 'vue';
import ProductSelectStep from '@/components/Ticket/Steps/ProductSelectStep.vue';
import SelectDomainStep from '@/components/Ticket/Steps/SelectDomainStep.vue';
import TicketStartStep from '@/components/Ticket/Steps/TicketStartStep.vue';
import { useI18n } from 'vue-i18n';
import CFCircleBackButton from '@/components/CFCircleBackButton.vue';
import SelectSSLStep from '@/components/Ticket/Steps/SelectSSLStep.vue';

const { t } = useI18n();
const ticketStore = useTicketStore();

const products = [
    {
        name: t('general.domain_hosting'),
        component: SelectDomainStep,
    },
    {
        name: t('general.ssl_certificates'),
        component: SelectSSLStep,
    },
    {
        name: t('general.other'),
        component: CreateTicketStep,
    },
];
const productType = shallowRef(null);

const selectProductType = (product) => {
    productType.value = product;
};

const goToNextStep = () => {
    if (!productType.value) {
        return;
    }

    ticketStore.setComponent(productType.value.component, ProductSelectStep);
};
</script>

<template>
    <CFTicketModalInner :title="$t('support.product_select_title')">
        <div class="flex flex-wrap gap-2">
            <button
                v-for="type in products"
                :key="type.name"
                type="button"
                class="rounded-sm border border-black px-4 py-2 transition duration-200 ease-in-out"
                :class="
                    productType?.name !== type.name
                        ? 'bg-transparent text-black hover:bg-black hover:text-white'
                        : 'bg-black text-white'
                "
                @click="selectProductType(type)"
            >
                {{ type.name }}
            </button>
        </div>

        <template #actions>
            <CFCircleBackButton @click="ticketStore.goToPrevious(TicketStartStep)">
                {{ $t('button.back') }}
            </CFCircleBackButton>

            <CFButton
                type="button"
                :disabled="!productType"
                @click="goToNextStep"
            >
                {{ $t('button.next') }}
            </CFButton>
        </template>
    </CFTicketModalInner>
</template>
