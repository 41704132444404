<script setup>
import CFProductBlockCol from '@/components/Dashboard/CFProductBlockCol.vue';
import { useSSLStore } from '@/stores/ssl.js';
import { useQuery } from '@tanstack/vue-query';

const sslStore = useSSLStore();

const certificatesQuery = useQuery({
    queryKey: ['certificates-count'],
    queryFn: async () => {
        const data = await sslStore.getSSLCertificates();

        return data.meta.total;
    },
    refetchOnWindowFocus: false,
});
</script>

<template>
    <CFProductBlockCol
        :name="$t('dashboard.ssl_certificates')"
        :amount="certificatesQuery.data.value ?? 0"
        :loading="certificatesQuery.isPending.value"
        href="ssl"
        order="new ssl"
    />
</template>
