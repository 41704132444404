<template>
    <svg
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M23 11.811A11.162 11.162 0 0 1 12 23 10.837 10.837 0 0 1 1 12.192 11.162 11.162 0 0 1 12 1a10.838 10.838 0 0 1 11 10.811Z"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        ></path>
        <path
            d="m12 14.005 0 -7"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        ></path>
        <path
            d="M11.991 16.005a0.245 0.245 0 0 0 -0.241 0.255 0.254 0.254 0 0 0 0.253 0.245h0a0.246 0.246 0 0 0 0.241 -0.255 0.253 0.253 0 0 0 -0.244 -0.245h-0.005"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
        ></path>
    </svg>
</template>
