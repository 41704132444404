<script setup>
import { Tab, TabGroup, TabList } from '@headlessui/vue';

defineProps({
    tabs: {
        type: Array,
        required: true,
    },
    selectedTab: {
        type: Number,
        default: 0,
    },
});

const emit = defineEmits(['changeTab']);

const changeTab = (index) => {
    emit('changeTab', index);
};
</script>

<template>
    <TabGroup
        class="mt-6 w-full"
        :selected-index="selectedTab"
        @change="changeTab"
    >
        <TabList class="flex gap-x-7">
            <Tab
                v-for="tab in tabs"
                :key="tab.title"
                v-slot="{ selected }"
                as="template"
            >
                <button
                    type="button"
                    class="text-primary py-2 text-base leading-5 font-semibold focus:outline-hidden"
                >
                    <span :class="[selected ? 'border-b-3 border-black pb-1' : 'border-transparent']">
                        {{ tab.title }}
                    </span>
                </button>
            </Tab>
        </TabList>
    </TabGroup>
</template>
