<script setup>
import { computed, ref } from 'vue';
import api from '@/plugins/axios.js';
import dayjs from 'dayjs';
import { unexpectedError } from '@/utils/unexpected-error.js';

const props = defineProps({
    notification: {
        type: Object,
        required: true,
    },
    color: {
        type: String,
        default: 'primary',
    },
    selected: {
        type: Object,
        default: null,
    },
});

const emit = defineEmits(['viewNotification']);

const isRead = ref(props.notification.attributes.read_at !== null);

const notificationClass = computed(() => {
    return {
        'unread': !isRead.value,
        'warning': props.notification.attributes.type === 'warning',
        'border-b-primary': props.color === 'primary',
        'border-b-secondary': props.color === 'secondary',
    };
});

const showNotification = () => {
    markAsRead(props.notification);

    emit('viewNotification', props.notification);
};

const markAsRead = async (notification) => {
    if (isRead.value) {
        return;
    }

    isRead.value = true;

    try {
        await api.post(`/general/notifications/${notification.id}/read`);
    } catch (e) {
        unexpectedError(e);
    }
};

const relativeDate = computed(() => dayjs().to(dayjs(props.notification.attributes.notified_at)));
</script>

<template>
    <div
        :class="[
            notificationClass,
            {
                'notification-active': selected?.id === notification.id,
            },
        ]"
        class="btn-notification"
        @click="showNotification"
    >
        <div class="btn-notification--container">
            <div class="relative z-10 flex h-full flex-col">
                <p>
                    {{ notification.attributes.subject }}
                </p>
                <div class="mt-1">
                    <span
                        v-tooltip="{
                            theme: 'light-tooltip',
                            content: dayjs(props.notification.attributes.notified_at).format('DD-MM-YYYY HH:mm'),
                        }"
                        class="text-xs hover:underline hover:underline-offset-3"
                    >
                        {{ relativeDate }}
                    </span>
                </div>
                <button
                    class="btn-notification--view"
                    type="button"
                >
                    <span class="text-sm leading-none">{{ $t('general.view_notification') }}</span>
                    <svg
                        :class="{ '-rotate-45': selected?.id !== notification.id }"
                        class="ml-2"
                        height="18"
                        viewBox="-0.5 -0.5 24 24"
                        width="18"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0.9583333333333334 11.498083333333334a10.541666666666668 10.541666666666668 0 1 0 21.083333333333336 0 10.541666666666668 10.541666666666668 0 1 0 -21.083333333333336 0Z"
                            fill="currentColor"
                            stroke="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1"
                        ></path>
                        <path
                            :stroke="color === 'secondary' && selected?.id !== notification.id ? 'black' : 'white'"
                            d="m5.75 11.498083333333334 12.458333333333334 0"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1"
                        ></path>
                        <path
                            :stroke="color === 'secondary' && selected?.id !== notification.id ? 'black' : 'white'"
                            d="m13.895833333333334 7.185583333333334 4.3125 4.3125 -4.3125 4.3125"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1"
                        ></path>
                    </svg>
                </button>
            </div>
        </div>

        <Transition name="fade">
            <div
                v-if="!isRead"
                class="btn-notification--alert"
            />
        </Transition>
    </div>
</template>

<style scoped>
@reference "@css/app.css";

.btn-notification {
    @apply relative flex h-[140px] w-full cursor-pointer flex-col justify-start border-b;

    &:only-child {
        @apply max-w-[calc(50%_-_0.5rem)];
    }

    svg {
        @apply fill-primary stroke-primary;

        path {
            @apply stroke-white;
        }
    }

    p {
        @apply line-clamp-3 text-left text-lg leading-4.5 break-words;
    }

    .btn-notification--container {
        @apply relative flex h-[140px] w-full flex-col justify-start overflow-hidden pt-4 pr-6 transition-all duration-200 ease-in-out before:absolute before:top-0 before:left-0 before:z-0 before:h-full before:w-full before:translate-y-full before:transition-all before:duration-300 before:ease-in-out last:mb-0;
    }

    &:hover,
    &:focus {
        .btn-notification--container {
            @apply text-secondary before:bg-primary px-3 before:translate-y-0;
        }

        svg {
            @apply fill-secondary stroke-primary rotate-0;

            path {
                @apply stroke-primary;
            }
        }
    }
}

.btn-notification--view {
    @apply mt-auto mb-5 flex items-end transition-all duration-75 ease-in-out;

    svg {
        @apply fill-current;
    }
}

.btn-notification--alert {
    @apply absolute top-[19px] -left-4 h-2 w-2 rounded-full bg-linear-to-b from-[#FF5300] to-[#E884E4];
}

#notifications-sidebar {
    .btn-notification {
        @apply border-0 px-8;

        &:only-child {
            @apply max-w-full;
        }

        .btn-notification--container {
            @apply border-b;
        }

        &:hover,
        &:focus {
            .btn-notification--container {
                @apply text-primary before:bg-white;
            }

            svg {
                path {
                    @apply stroke-secondary;
                }
            }
        }

        svg {
            path {
                @apply stroke-primary;
            }
        }
    }

    .btn-notification--alert {
        @apply left-3;
    }

    .btn-notification.notification-active {
        @apply text-primary before:bg-neutrals-300 relative before:absolute before:-top-px before:left-8 before:z-0 before:h-[calc(100%_+_1px)] before:w-[calc(100%_-_2.5rem)];

        .btn-notification--container {
            @apply px-3;
        }

        &:hover,
        &:focus {
            @apply text-primary;

            .btn-notification--container {
                @apply before:bg-neutrals-300;
            }
        }

        svg {
            path {
                @apply stroke-white;
            }
        }
    }
}
</style>
