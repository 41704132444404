<template>
    <button type="button">
        <svg
            viewBox="-0.1 -0.1 6 6"
            xmlns="http://www.w3.org/2000/svg"
            height="6"
            width="6"
        >
            <path
                d="m0.18125 5.618508333333333 5.4375 -5.4375"
                fill="currentColor"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
            ></path>
            <path
                d="M5.61875 5.618508333333333 0.18125 0.18100833333333333"
                fill="currentColor"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
            ></path>
        </svg>
    </button>
</template>
