<script setup>
import CFTicketModalInner from '@/components/Ticket/CFTicketModalInner.vue';
import { useTicketStore } from '@/stores/ticket.js';
import ConversationQuestionWarningIcon from '@/components/icons/ConversationQuestionWarningIcon.vue';
import CFButton from '@/components/CFButton.vue';
import LightBulbShineIcon from '@/components/icons/LightBulbShineIcon.vue';
import TicketStartStep from '@/components/Ticket/Steps/TicketStartStep.vue';
import SupportOptionsStep from '@/components/Ticket/Steps/SupportOptionsStep.vue';
import PhoneActionsQuestion from '@/components/icons/PhoneActionsQuestion.vue';
import { useBusinessHours } from '@/composables/useBusinessHours.js';

const ticketStore = useTicketStore();

const { isInsideBusinessHours } = useBusinessHours();

const callPhoneNumber = () => {
    window.open('tel:+310407114496', '_self');
};

const goToTicketStartStep = () => {
    ticketStore.setComponent(TicketStartStep, SupportOptionsStep);
};

const goToRoadmap = () => {
    window.open('https://roadmap.cyberfusion.io/', '_blank');
};
</script>

<template>
    <CFTicketModalInner :title="$t('support.overview_modal_title')">
        <p class="mb-6 text-sm">{{ $t('support.overview_modal_description') }}</p>

        <div class="mb-2 grid grid-cols-2 gap-2">
            <div class="col-span-1 flex flex-col items-center bg-white px-7 pt-3 pb-5">
                <div
                    class="h-1.5 w-1.5 rounded-full"
                    :class="isInsideBusinessHours() ? 'bg-green-500' : 'bg-red-500'"
                ></div>
                <h3 class="mt-1 text-xs font-bold">{{ $t('general.question') }}</h3>
                <ConversationQuestionWarningIcon class="mt-5 mb-2 size-6" />
                <p class="text-center text-xs leading-snug">
                    {{ $t('support.question_description') }}
                </p>
                <p class="text-2xs mt-2 mb-3 text-center text-green-400">
                    {{ $t('general.free') }}
                </p>
                <CFButton
                    size="small"
                    outline
                    type="button"
                    class="mt-auto"
                    @click="goToTicketStartStep"
                >
                    {{ $t('button.ask_question') }}
                </CFButton>
            </div>

            <div class="col-span-1 flex flex-col items-center bg-white px-7 pt-3 pb-5">
                <div class="h-1.5 w-1.5 rounded-full bg-green-500"></div>
                <h3 class="mt-1 text-xs font-bold">{{ $t('general.urgent_problem') }}</h3>
                <PhoneActionsQuestion class="mt-5 mb-2 size-6" />
                <p class="text-center text-xs leading-snug">{{ $t('support.urgent_problem_description') }}*</p>
                <p class="text-2xs text-neutrals-700 mt-2 mb-2 text-center leading-snug">
                    *{{ $t('support.charges_may_apply') }}.
                </p>
                <p class="text-2xs text-neutrals-700 mb-3 text-center leading-snug">
                    {{ $t('support.not_available_for', { product: 'Hosting Plan Something' }) }}
                </p>
                <CFButton
                    size="small"
                    outline
                    type="button"
                    class="mt-auto"
                    @click="callPhoneNumber"
                >
                    <!-- eslint-disable-next-line -->
                    {{ $t('general.call') }} +31 (0)40 711 44 96
                </CFButton>
            </div>

            <div class="col-span-2 bg-white">
                <div class="flex items-center gap-5 p-5">
                    <div class="shrink-0">
                        <LightBulbShineIcon class="h-7 w-7" />
                    </div>
                    <div class="grow">
                        <h3 class="mb-1 text-xs font-bold">{{ $t('support.idea_title') }}</h3>
                        <p class="text-xs">{{ $t('support.idea_description') }}</p>
                    </div>
                    <div class="shrink-0">
                        <CFButton
                            size="small"
                            outline
                            type="button"
                            @click="goToRoadmap"
                        >
                            {{ $t('support.share_your_idea') }}
                        </CFButton>
                    </div>
                </div>
            </div>
        </div>
    </CFTicketModalInner>
</template>
