<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        height="24"
        width="24"
    >
        <g>
            <path
                d="M23.5 12A11.5 11.5 0 1 1 12 0.48 11.5 11.5 0 0 1 23.5 12Z"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
            ></path>
            <path
                d="m20.15 3.87 -1.21 3a2 2 0 0 1 -1.61 1.24l-2.13 0.29a0.49 0.49 0 0 0 -0.38 0.26 0.5 0.5 0 0 0 0 0.46l0.54 1.09a0.5 0.5 0 0 0 0.45 0.27c0.34 0 0.63 -0.05 0.81 0.24a0.5 0.5 0 0 1 0 0.49C14.91 14.65 15 14.44 15 14.6v2.45a2 2 0 0 1 -0.72 1.53l-1.9 1.58a0.49 0.49 0 0 1 -0.7 -0.06l-1.53 -1.84a2 2 0 0 1 -0.4 -1.76l0.21 -0.84a0.57 0.57 0 0 0 0 -0.34l-0.78 -1.56a0.51 0.51 0 0 0 -0.45 -0.28H7a2 2 0 0 1 -1.92 -1.42l-0.67 -2.19a0.5 0.5 0 0 1 0.1 -0.47l1.73 -2a2 2 0 0 1 1.91 -0.67l1.3 0.27L13 7a0.5 0.5 0 0 0 0.5 -0.5V5.34a2 2 0 0 0 -1.26 -1.86c-0.27 -0.11 -0.57 -0.18 -0.59 -0.54a0.49 0.49 0 0 1 0.25 -0.47L14.68 0.88"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
            ></path>
        </g>
    </svg>
</template>
